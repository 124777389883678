/* styles.css */

/* Container for the entire application */
body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items to the top */
    background-color: #f5e6c9; /* Yellowish background color */
    background-image: url('background.png');
    background-size: cover; 
    background-position: center; 
    width: 100vw;
    height: 100vh;
    background-attachment: fixed;
  }
  
  /* Container for the book and character boxes */
  .book-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }
  
  /* Book pages */
  .page {
    width: 80%;
    max-width: 600px;
    height: auto;
    margin: 20px;
    margin-top: 0px;
    padding: 20px;
    border: 1px solid #8a2be2; /* Purple border */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f5e6c9; /* White background color for the "paper" */
  }
  
  /* Page content */
  .page-content {
    font-size: 18px;
    line-height: 1.5;
  }

  .game-list-container {
      background-image: url('background.png');
      background-size: cover; 
      background-position: center; 
      width: 100vw;
      height: 100vh;
      background-attachment: fixed;
  }

  .game-page-container {
    background-image: url('background.png');
    background-size: cover; 
    background-position: center; 
    width: 100vw;
    height: 100vh;
    background-attachment: fixed;
}

/* Character box */
.character-box {
    display: flex; /* Use flexbox */
    flex-wrap: wrap; /* Allow wrapping */
    width: 80%;
    margin: 20px auto; /* Center the character box horizontally */
    padding: 20px;
    justify-content: space-between; /* Spread items evenly */
  }
  
  /* Individual character box */
  .character {
    width: calc(50% - 10px); /* Set width for each character box with margin */
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* Include padding and border in width calculation */
  }
  
  .character.keiser {
    background-color: #9062a0; /* Light orange background */
  }
  
  .character.vandrer {
    background-color: #616161; /* Light blue background */
  }
  
  .character.samler {
    background-color: #46b4d8; /* Light green background */
  }
  
  
  .character.sjaman {
    background-color: #aeba42;
  }

  .character.speider {
    background-color: #d15937;
  }
  /* Adjust styles for other characters as needed */
  
  
  
  
  /* Navigation buttons */
  .navigation {
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 600px;
  }
  
  .navigation-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .navigation-button:hover {
    background-color: #0056b3;
  }
  